import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://inventory.raicotech.cl:4500',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    },
    withCredentials: false // Importante: deshabilitar withCredentials
});

// Interceptor para manejar errores
instance.interceptors.request.use(
    config => {
        // Asegurarse de que la URL sea https
        if (!config.url.includes('https://')) {
            config.url = config.url.replace('http://', 'https://');
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.code === 'ERR_NETWORK') {
            console.error('Error de conexión:', error);
            const originalRequest = error.config;
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                // Intentar sin el puerto 4500
                const newUrl = originalRequest.url.replace(':4500', '');
                return instance({
                    ...originalRequest,
                    url: newUrl,
                    baseURL: 'https://inventory.raicotech.cl'
                });
            }
        }
        return Promise.reject(error);
    }
);

export default instance;
