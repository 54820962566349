import React, { useState } from 'react';
import './orders.css';
import NavBar from '../navBar/navBar.jsx';
import { Button, Modal, Form, Card } from 'react-bootstrap';
import OrderStatusTracker from './OrderStatusTracker/OrderStatusTracker.jsx';

function Orders() {
  const initialOrders = [
    { order: '#001', date: '01-11-24', user: 'User1', customerName: 'John Doe', cantidad: 3, precio: '$150', estado: 'Completado', tipoPago: 'Tarjeta de Crédito', tipoOrden: 'Express', grupo: 'Grupo A' },
    // Agrega más órdenes aquí para probar
  ];

  const [orders, setOrders] = useState(initialOrders);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);  // Modal para agregar orden
  const [orderToEdit, setOrderToEdit] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('order'); // campo por defecto

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Función de filtrado
  const filteredOrders = orders.filter(order => {
    if (!searchTerm) return true;

    const searchValue = searchTerm.toLowerCase();
    switch (searchField) {
      case 'order':
        return order.order.toLowerCase().includes(searchValue);
      case 'date':
        return order.date.toLowerCase().includes(searchValue);
      case 'user':
        return order.user.toLowerCase().includes(searchValue);
      case 'customer':
        return order.customerName.toLowerCase().includes(searchValue);
      case 'quantity':
        return order.cantidad.toString().includes(searchValue);
      case 'price':
        return order.precio.toLowerCase().includes(searchValue);
      case 'status':
        return order.estado.toLowerCase().includes(searchValue);
      case 'payment':
        return order.tipoPago.toLowerCase().includes(searchValue);
      case 'type':
        return order.tipoOrden.toLowerCase().includes(searchValue);
      case 'group':
        return order.grupo.toLowerCase().includes(searchValue);
      default:
        return true;
    }
  });

  // Actualizar currentOrders para usar los resultados filtrados
  const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

  // Manejar cambio de página
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Eliminar orden
  const handleDelete = (order) => {
    const updatedOrders = orders.filter(o => o.order !== order.order);
    setOrders(updatedOrders);
  };

  // Abrir modal de edición
  const handleEdit = (order) => {
    setOrderToEdit(order);
    setShowEditModal(true);
  };

  // Guardar cambios de edición
  const handleSaveEdit = () => {
    setOrders(orders.map(o => (o.order === orderToEdit.order ? orderToEdit : o)));
    setShowEditModal(false);
  };

  // Abrir modal de agregar orden
  const handleAddOrder = () => {
    setShowAddModal(true);
  };

  // Cerrar modal de agregar orden
  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  // Función para formatear fecha
  const formatDate = (date) => {
    if (!date) return '';
    const dateObj = new Date(date);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear().toString().slice(-2);
    return `${day}-${month}-${year}`;
  };

  // Manejar el envío del formulario para agregar una nueva orden
  const handleAddOrderSubmit = (e) => {
    e.preventDefault();
    const newOrder = {
      order: `#00${orders.length + 1}`,
      date: formatDate(new Date()),
      user: 'User2',
      customerName: e.target.customerName.value,
      cantidad: e.target.cantidad.value,
      precio: e.target.precio.value,
      estado: 'Pending',
      tipoPago: e.target.tipoPago.value,
      tipoOrden: e.target.tipoOrden.value,
      grupo: e.target.grupo.value
    };
    setOrders([...orders, newOrder]);
    setShowAddModal(false);
  };

  // Modificar la función handleSaveEdit para incluir el cambio de estado
  const handleStatusChange = (orderId, newStatus) => {
    const updatedOrders = orders.map(order => {
      if (order.order === orderId) {
        return { ...order, estado: newStatus };
      }
      return order;
    });
    setOrders(updatedOrders);
  };

  return (
    <div className="ResponsiveShiet12">
      <div className="Body_Content12">
        <div className="NavBar">
          <NavBar />
          <div className="Tittle12">
            <h2>Order Lists</h2>
          </div>
          
          <div className="container">
            {/* Card de Órdenes */}
            <Card className="orders-card mb-4">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <h3>Orders Management</h3>
                <Button variant="primary" onClick={handleAddOrder}>
                  <i className="bi bi-plus-circle me-2"></i>
                  Add New Order
                </Button>
              </Card.Header>

              <Card.Body>
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Order #</th>
                        <th>Date</th>
                        <th>User</th>
                        <th>Customer</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Status</th>
                        <th>Payment</th>
                        <th>Type</th>
                        <th>Group</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentOrders.map((order, index) => (
                        <tr key={index}>
                          <td>{order.order}</td>
                          <td>{order.date}</td>
                          <td>{order.user}</td>
                          <td>{order.customerName}</td>
                          <td>{order.cantidad}</td>
                          <td>{order.precio}</td>
                          <td>
                            <span className={`status-badge status-${order.estado.toLowerCase().replace(' ', '-')}`}>
                              {order.estado}
                            </span>
                          </td>
                          <td>{order.tipoPago}</td>
                          <td>{order.tipoOrden}</td>
                          <td>{order.grupo}</td>
                          <td>
                            <div className="btn-group">
                              <Button 
                                variant="outline-info" 
                                size="sm" 
                                onClick={() => handleEdit(order)}
                                className="me-1"
                              >
                                <i className="bi bi-pencil-square"></i>
                              </Button>
                              <Button 
                                variant="outline-danger" 
                                size="sm" 
                                onClick={() => handleDelete(order)}
                              >
                                <i className="bi bi-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>

              <Card.Footer className="d-flex justify-content-between align-items-center">
                <div className="pagination-info">
                  Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, orders.length)} of {orders.length} entries
                </div>
                <div className="pagination">
                  {Array.from({ length: totalPages }, (_, i) => (
                    <Button
                      key={i}
                      variant={currentPage === i + 1 ? 'primary' : 'outline-secondary'}
                      onClick={() => handlePageChange(i + 1)}
                      className="me-1"
                    >
                      {i + 1}
                    </Button>
                  ))}
                </div>
              </Card.Footer>
            </Card>

            {/* Nueva sección para el OrderStatusTracker */}
            <div className="row">
              <div className="col-12">
                <Card className="orders-card">
                  <Card.Header>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h3>Order Status Overview</h3>
                      <div className="d-flex gap-2">
                        <Form.Select 
                          value={searchField}
                          onChange={(e) => setSearchField(e.target.value)}
                          style={{ width: '150px' }}
                        >
                          <option value="order">Order #</option>
                          <option value="date">Date</option>
                          <option value="user">User</option>
                          <option value="customer">Customer</option>
                          <option value="quantity">Quantity</option>
                          <option value="price">Price</option>
                          <option value="status">Status</option>
                          <option value="payment">Payment</option>
                          <option value="type">Type</option>
                          <option value="group">Group</option>
                        </Form.Select>
                        <Form.Control
                          type="text"
                          placeholder="Search orders..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          style={{ width: '250px' }}
                        />
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body className="p-3">
                    <div className="row">
                      {currentOrders.length > 0 ? (
                        currentOrders.map((order, index) => (
                          <div key={index} className="col-md-3 mb-3">
                            <OrderStatusTracker
                              currentStatus={order.estado}
                              onStatusChange={handleStatusChange}
                              orderId={order.order}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="col-12 text-center py-4">
                          <p className="text-muted">No orders found matching your search criteria.</p>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de Agregar Orden - Mejorado */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddOrderSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Customer Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="customerName"
                    placeholder="Enter customer name"
                    required
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    name="cantidad"
                    placeholder="Enter quantity"
                    required
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="text"
                    name="precio"
                    placeholder="Enter price"
                    required
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Payment Method</Form.Label>
                  <Form.Select name="tipoPago" required>
                    <option value="">Select payment method</option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Cash">Cash</option>
                    <option value="Transfer">Transfer</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Order Type</Form.Label>
                  <Form.Select name="tipoOrden" required>
                    <option value="">Select order type</option>
                    <option value="Express">Express</option>
                    <option value="Normal">Normal</option>
                  </Form.Select>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label>Group</Form.Label>
                  <Form.Control
                    type="text"
                    name="grupo"
                    placeholder="Enter group"
                    required
                  />
                </Form.Group>
              </div>
            </div>

            <div className="text-end">
              <Button variant="secondary" onClick={handleCloseAddModal} className="me-2">
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Create Order
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal de Edición - Mejorado */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Order #{orderToEdit?.order}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              {orderToEdit && (
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={orderToEdit.customerName}
                      onChange={(e) => setOrderToEdit({ ...orderToEdit, customerName: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                      type="number"
                      value={orderToEdit.cantidad}
                      onChange={(e) => setOrderToEdit({ ...orderToEdit, cantidad: e.target.value })}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="text"
                      value={orderToEdit.precio}
                      onChange={(e) => setOrderToEdit({ ...orderToEdit, precio: e.target.value })}
                    />
                  </Form.Group>
                </Form>
              )}
            </div>
            <div className="col-md-6">
              {orderToEdit && (
                <OrderStatusTracker 
                  currentStatus={orderToEdit.estado}
                  onStatusChange={handleStatusChange}
                  orderId={orderToEdit.order}
                />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Orders;
