import React, { useState, useEffect } from 'react';
import './productManagement.css';
import { Form, Input, Button, message, Table, Modal, Select, InputNumber, Space } from 'antd';
import { useStore } from '../store/store.jsx';
import NavBar from '../navBar/navBar.jsx';
import { SearchOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;
const { Search } = Input;

const ProductManagement = () => {
  const { products, setProducts } = useStore();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState('create');
  const [selectedProduct, setSelectedProduct] = useState(null);

  const currencies = ['CLP', 'USD', 'EUR'];
  const categories = [
    'Ropa', 'Accesorios', 'Electrodomésticos', 'Electrónica', 'Juguetes',
    'Muebles', 'Herramientas', 'Artículos de limpieza', 'Artículos de oficina',
    'Medicamentos', 'Libros', 'Materiales de construcción', 'Equipos de seguridad',
    'Instrumentos musicales', 'Accesorios para mascotas'
  ];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'productId',
      key: 'productId',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (price, record) => `${price} ${record.currency}`,
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: 'Stock',
      dataIndex: 'stockQuantity',
      key: 'stockQuantity',
      sorter: (a, b) => a.stockQuantity - b.stockQuantity,
    },
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
      filters: categories.map(cat => ({ text: cat, value: cat })),
      onFilter: (value, record) => record.category === value,
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button 
            type="primary" 
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          />
          <Button 
            danger 
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.productId)}
          />
        </Space>
      ),
    },
  ];

  const fetchProducts = async (searchTerm = '') => {
    setSearchLoading(true);
    try {
      const response = await fetch('http://localhost:3500/getProducts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          [JSON.stringify({
            nick: localStorage.getItem('username'),
            pwd: localStorage.getItem('password'),
            searchTerm
          })]: ''
        })
      });

      const data = await response.json();
      if (response.ok) {
        setProducts(data.products);
      } else {
        message.error(data.data || 'Error fetching products');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Error connecting to server');
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const endpoint = modalMode === 'create' ? 'createProduct' : 'updateProduct';
      const productData = {
        nick: localStorage.getItem('username'),
        pwd: localStorage.getItem('password'),
        ...values,
        ...(modalMode === 'edit' && { productId: selectedProduct.productId })
      };

      const response = await fetch(`http://localhost:3500/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          [JSON.stringify(productData)]: ''
        })
      });

      const data = await response.json();

      if (response.ok) {
        message.success(`Product ${modalMode === 'create' ? 'created' : 'updated'} successfully`);
        setIsModalVisible(false);
        form.resetFields();
        fetchProducts();
      } else {
        message.error(data.data || `Error ${modalMode === 'create' ? 'creating' : 'updating'} product`);
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Error connecting to server');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (productId) => {
    try {
      const response = await fetch('http://localhost:3500/deleteProduct', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          [JSON.stringify({
            nick: localStorage.getItem('username'),
            pwd: localStorage.getItem('password'),
            productId
          })]: ''
        })
      });

      const data = await response.json();

      if (response.ok) {
        message.success('Product deleted successfully');
        fetchProducts();
      } else {
        message.error(data.data || 'Error deleting product');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Error connecting to server');
    }
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setModalMode('edit');
    form.setFieldsValue(product);
    setIsModalVisible(true);
  };

  const showCreateModal = () => {
    setModalMode('create');
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <div className='product-management-container'>
      <div className='header'>
        <h1>Product Management</h1>
        <NavBar />
      </div>

      <div className="search-section">
        <div className="search-header">
          <Search
            placeholder="Search products"
            onSearch={fetchProducts}
            loading={searchLoading}
            enterButton
          />
          <Button type="primary" icon={<PlusOutlined />} onClick={showCreateModal}>
            Add Product
          </Button>
        </div>
      </div>

      <Modal
        title={`${modalMode === 'create' ? 'Add New' : 'Edit'} Product`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          {/* Formulario continúa en el siguiente mensaje... */}
        </Form>
      </Modal>
    </div>
  );
};

export default ProductManagement;
