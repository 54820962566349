import React from 'react';
import { Package, ShoppingCart, Users, UserPlus } from 'lucide-react';
import DashboardCard from './BodyContent/dashboardCard/dashboardCard';
import { useStore } from '../store/store.jsx';
import './dasboardApp.css';
import NavBar from '../navBar/navBar.jsx';

const Dashboard = () => {
  const { products, orders, customers } = useStore();
  
  const newCustomers = customers.filter(
    (customer) => new Date().getTime() - new Date(customer.createdAt).getTime() < 30 * 24 * 60 * 60 * 1000
  ).length;

  return (
    <div className='ResponsiveShiet12' id='dashboard'>
      
      <div className='Body_Content12'id='dashboard'>
      <div className='NavBar' id='dashboard'>
      <NavBar />
        <div className='Tittle12' id='dashboard'>
          <h2 id='dashboard'>Dashboard Overview</h2>
        </div>
    <div className="flex-grow-1 p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <DashboardCard
          title="Total Products"
          value={products.length}
          icon={Package}
          trend={5}
        />
        <DashboardCard
          title="Total Orders"
          value={orders.length}
          icon={ShoppingCart}
          trend={12}
        />
        <DashboardCard
          title="Total Customers"
          value={customers.length}
          icon={Users}
          trend={8}
        />
        <DashboardCard
          title="New Customers"
          value={newCustomers}
          icon={UserPlus}
          trend={15}
        />
      </div>

      <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4">Recent Orders</h2>
          {/* Add recent orders table/list here */}
        </div>
        
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-lg font-semibold mb-4">New Customers</h2>
          {/* Add new customers list here */}
        </div>
      </div>
    </div>
    </div>
      </div>
    </div>
  );
};

export default Dashboard;