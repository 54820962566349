import React, { useState } from 'react';
import './config_usr.css';
import Nav from '../navBar/navBar.jsx';
import { Button, Form, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useStore } from '../store/store.jsx'; 

// import api from './ '; // Falta colocar la api dentro del directorio de SRC

const ConfigUsr = () => {
  const { currentUser , setCurrentUser  } = useStore(); // Obtén el usuario actual y la función para actualizarlo

  const [userInfo, setUserInfo] = useState({
    nick: currentUser.nick || '',
    firstName: currentUser.firstName || '',
    lastName: currentUser.lastName || '',
    email: currentUser.email || '',
    phoneNumber: currentUser.phoneNumber || '',
    business: currentUser.business || '',
    enterprise: currentUser.enterprise || '',
    profileImage: currentUser.profileImage || '',
  });

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleImageChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} cargado con éxito`);
      setUserInfo({ ...userInfo, profileImage: info.file.originFileObj });
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} no se pudo cargar`);
    }
  };

  const handleUserSubmit = async (e) => {
    e.preventDefault();
    console.log('Información del usuario actualizada:', userInfo);
    
    try {
      // Aquí realiza la llamada a la API para guardar los cambios
      const formData = new FormData();
      Object.keys(userInfo).forEach(key => {
        formData.append(key, userInfo[key]);
      });

     // const response = await api.saveUserData(formData); // Asegúrate de que esta función esté definida en tu API
     // console.log('Respuesta de la API:', response);

      // Actualiza el usuario en el store
      setCurrentUser (userInfo);
      message.success('Información del usuario actualizada con éxito');
    } catch (error) {
      console.error('Error al guardar cambios:', error);
      message.error('Error al actualizar la información del usuario');
    }
  };

  return (
    <div className='Body_user_Config' id='ConfigUsr'>
      <div className='Tittle_user_Config' id='ConfigUsr'>
        <h2 id='ConfigUsr'>User Configuration</h2>
      </div>

      <div className='config-container' id='ConfigUsr'>
        <Form onSubmit={handleUserSubmit} layout="vertical">
          <Form.Item label="User Nick">
            <Input
              name="nick"
              value={userInfo.nick}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="First Name">
            <Input
              name="firstName"
              value={userInfo.firstName}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="Last Name">
            <Input
              name="lastName"
              value={userInfo.lastName}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              name="email"
              type="email"
              value={userInfo.email}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="Phone Number">
            <Input
              name="phoneNumber"
              value={userInfo.phoneNumber}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="Business">
            <Input
              name="business"
              value={userInfo.business}
              onChange={handleUserChange}
              required
            />
 </Form.Item>
          <Form.Item label="Enterprise">
            <Input
              name="enterprise"
              value={userInfo.enterprise}
              onChange={handleUserChange}
              required
            />
          </Form.Item>
          <Form.Item label="Profile Photo">
            <Upload
              name="profileImage"
              showUploadList={false}
              onChange={handleImageChange}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Upload </Button>
            </Upload>
          </Form.Item>
          <Form.Item style={{ marginTop: '16px' }}>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
        {userInfo.profileImage && (
          <div className="profile-image-preview">
            <h3>Profile Photo:</h3>
            <img
              src={URL.createObjectURL(userInfo.profileImage)}
              alt="Perfil"
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfigUsr;