import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import './CommentSection.css';

const CommentSection = () => {
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (comment.trim()) {
      setComments([...comments, {
        text: comment,
        timestamp: new Date().toLocaleString()
      }]);
      setComment('');
    }
  };

  return (
    <div className="mt-5 border-top pt-4">
      <h4>Deja un comentario en tu perfil</h4>
      <Form onSubmit={handleSubmit} className="mb-4">
        <Form.Group controlId="commentForm">
          <Form.Control
            as="textarea"
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Escribe tu comentario aquí..."
            className="mb-2"
          />
          <div className="d-flex justify-content-center">
            <Button variant="primary" type="submit">
              Publicar comentario
            </Button>
          </div>
        </Form.Group>
      </Form>

      <h5>Comentarios recientes</h5>
      {comments.length === 0 ? (
        <p className="text-muted">Aún no tienes comentarios. ¡Sé el primero en dejar uno!</p>
      ) : (
        comments.map((comment, index) => (
          <div key={index} className="card mb-3">
            <div className="card-body">
              <p className="card-text">{comment.text}</p>
              <small className="text-muted">{comment.timestamp}</small>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default CommentSection;