import React, { useState } from 'react';
import { Form, Input, Button, Select, message, Tabs } from 'antd';
import './product.css'; // Make sure to have this CSS file
import NavBar from '../navBar/navBar.jsx';
import { useStore } from '../store/store.jsx'; // Import the store

const { Option } = Select;
const { TabPane } = Tabs;

const Product = () => {
  const [form] = Form.useForm();
  const { addProduct } = useStore(); // Get the addProduct function from the store
  const [activeTab, setActiveTab] = useState('1');

  const handleSubmit = (values) => {
    // Add the product to the store
    addProduct(values);
    message.success('Product saved successfully');
    form.resetFields(); // Reset the form fields after submission
  };

  return (
    <div className='product-container'>
      <NavBar />
      <div className="form-container">
        <h1>Product Management</h1>
        <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
          <TabPane tab="Información del producto" key="1">
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              
              <Form.Item name="id" label="Product ID" rules={[{ required: true, message: 'Please enter the product ID' }]}>
                <Input placeholder="Enter the product ID" />
              </Form.Item>
              <Form.Item name="name" label="Product Name" rules={[{ required: true, message: 'Please enter the product name' }]}>
                <Input placeholder="Enter the product name" />
              </Form.Item>
              <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please enter the description' }]}>
                <Input.TextArea placeholder="Enter the description" />
              </Form.Item>
              <Form.Item name="price" label="Price" rules={[{ required: true, message: 'Please enter the price' }]}>
                <Input type="number" placeholder="Enter the price" />
              </Form.Item>
              <Form.Item name="cost" label="Cost" rules={[{ required: true, message: 'Please enter the cost' }]}>
                <Input type="number" placeholder="Enter the cost" />
              </Form.Item>
              <Form.Item name="currency" label="Currency" rules={[{ required: true, message: 'Please select the currency' }]}>
                <Select placeholder="Select the currency">
                  <Option value="CLP">Peso CLP</Option>
                  <Option value="USD">Dollar</Option>
                  <Option value="EUR">EURO</Option>
                </Select>
              </Form.Item>
              <Form.Item name="stockQuantity" label="Stock Quantity" rules={[{ required: true, message: 'Please enter the stock quantity' }]}>
                <Input type="number" placeholder="Enter the stock quantity" />
              </Form.Item>
              <Form.Item name="minStock" label="Minimum Stock" rules={[{ required: true, message: 'Please enter the minimum stock' }]}>
                <Input type="number" placeholder="Enter the minimum stock" />
              </Form.Item>
              <Form.Item name="unit" label="Unit of Measure" rules={[{ required: true, message: 'Please enter the unit of measure' }]}>
                <Input placeholder="Enter the unit of measure" />
              </Form.Item>
              <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Please select the category' }]}>
                <Select placeholder="Select the category">
                  <Option value="Clothing">Clothing</Option>
                  <Option value="Accessories">Accessories</Option>
                  <Option value="Electronics">Electronics</Option>
                  <Option value="Toys">Toys</Option>
                  <Option value="Furniture">Furniture</Option>
                  <Option value="Tools">Tools </Option>
                  <Option value="Cleaning">Cleaning Items</Option>
                  <Option value="Office">Office Items</Option>
                  <Option value="Medicines">Medicines</Option>
                  <Option value="Books">Books</Option>
                  <Option value="Construction">Construction Materials</Option>
                  <Option value="Safety">Safety Equipment</Option>
                  <Option value="Musical">Musical Instruments</Option>
                  <Option value="Pets">Pet Accessories</Option>
                </Select>
              </Form.Item>



            </Form>
          </TabPane>

          <TabPane tab="Detalles Adicionales" key="2">
            <div>
              <h2>Detalles del Producto</h2>
            </div>
            <Form.Item name="brand" label="Brand" rules={[{ required: true, message: 'Please enter the brand' }]}>
                <Input placeholder="Enter the brand" />
              </Form.Item>

              <Form.Item name="weight" label="Weight" rules={[{ required: true, message: 'Please enter the weight' }]}>
                <Input placeholder="Enter the weight" />
              </Form.Item>

              <Form.Item name="dimensions" label="Dimensions" rules={[{ required: true, message: 'Please enter the dimensions' }]}>
                <Input placeholder="Enter the dimensions" />
              </Form.Item>

              <Form.Item name="barcode" label="Barcode" rules={[{ required: true, message: 'Please enter the barcode' }]}>
                <Input placeholder="Enter the barcode" />
              </Form.Item>

              <Form.Item name="sku" label="SKU" rules={[{ required: true, message: 'Please enter the SKU' }]}>
                <Input placeholder="Enter the SKU" />
              </Form.Item>

              <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please select the status' }]}>
                <Select placeholder="Select the status">
                  <Option value="active">Active</Option>
                  <Option value="inactive">Inactive</Option>
                </Select>
              </Form.Item>

              <Form.Item name="creationDate" label="Creation Date" rules={[{ required: true, message: 'Please select the creation date' }]}>
                <Input type="date" />
              </Form.Item>

              <Form.Item name="updateDate" label="Last Update Date" rules={[{ required: true, message: 'Please select the last update date' }]}>
                <Input type="date" />
              </Form.Item>

              <Form.Item label="Video">
                <Input placeholder="Enter video URL" />
              </Form.Item>

              <Form.Item name="supplier" label="Supplier" rules={[{ required: true, message: 'Please enter the supplier' }]}>
                <Input placeholder="Enter the supplier" />
              </Form.Item>

              <Form.Item name="additionalFeatures" label="Additional Features">
                <Input.TextArea placeholder="Enter additional features" />
              </Form.Item>
            
            <Form.Item>
                <Button type="primary" htmlType="submit">Save Product</Button>
              </Form.Item>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Product;