import React, { useState } from 'react';
import { Input, Button, Card, message, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './UserSearch.css';

const UserSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [userResult, setUserResult] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'Nick',
      dataIndex: 'nick',
      key: 'nick',
      sorter: (a, b) => a.nick.localeCompare(b.nick),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Business',
      dataIndex: 'business',
      key: 'business',
      filters: [
        { text: 'Sales', value: 'Sales' },
        { text: 'Marketing', value: 'Marketing' },
        { text: 'IT', value: 'IT' },
      ],
      onFilter: (value, record) => record.business === value,
    },
    {
      title: 'Type',
      dataIndex: 'userType',
      key: 'userType',
      filters: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Admin', value: 'Admin' },
      ],
      onFilter: (value, record) => record.userType === value,
    }
  ];

  const handleSearch = async () => {
    if (!searchTerm) {
      message.warning('Please enter a search term');
      return;
    }

    setLoading(true);
    try {
      const payload = {
        nick: localStorage.getItem('username'),
        pwd: localStorage.getItem('password'),
        targetUser: searchTerm
      };

      const response = await fetch('http://localhost:3500/getUsers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          [JSON.stringify(payload)]: ''
        })
      });

      const data = await response.json();

      if (response.ok) {
        setUsersData(data.users.map(user => ({
          ...user,
          key: user.nick
        })));
      } else {
        message.error(data.data || 'Error searching users');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Error connecting to server');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="user-search-container">
      <div className="search-header">
        <h2>User Search</h2>
        <div className="search-box">
          <Input
            className="search-input"
            placeholder="Search by nick, name or email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onPressEnter={handleSearch}
            prefix={<SearchOutlined className="search-icon" />}
          />
          <Button 
            type="primary" 
            onClick={handleSearch}
            loading={loading}
            className="search-button"
          >
            Search
          </Button>
        </div>
      </div>

      <div className="users-table">
        <Table 
          columns={columns}
          dataSource={usersData}
          loading={loading}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          scroll={{ x: true }}
          size="middle"
          bordered
          rowClassName={(record) => record.userType === 'Admin' ? 'admin-row' : ''}
          onRow={(record) => ({
            onClick: () => setUserResult(record),
          })}
        />
      </div>

      {userResult && (
        <Card className="user-card">
          <h3 className="card-title">User Details</h3>
          <div className="user-info-grid">
            <div className="info-item">
              <label>Nick:</label>
              <span>{userResult.nick}</span>
            </div>
            <div className="info-item">
              <label>First Name:</label>
              <span>{userResult.firstName}</span>
            </div>
            <div className="info-item">
              <label>Last Name:</label>
              <span>{userResult.lastName}</span>
            </div>
            <div className="info-item">
              <label>Email:</label>
              <span>{userResult.email}</span>
            </div>
            <div className="info-item">
              <label>Phone:</label>
              <span>{userResult.phoneNumber}</span>
            </div>
            <div className="info-item">
              <label>Business:</label>
              <span>{userResult.business}</span>
            </div>
            <div className="info-item">
              <label>Enterprise:</label>
              <span>{userResult.enterprise}</span>
            </div>
            <div className="info-item">
              <label>User Type:</label>
              <span>{userResult.userType}</span>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default UserSearch; 