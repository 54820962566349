import { create } from 'zustand';

// Datos Usuario
// # "nick"/"firstName"/"lastName"/"email"/"phoneNumber"/"business"/"enterprise"/"pwd" 
const storedUserType = JSON.parse(sessionStorage.getItem("userType")); // Asegúrate de parsear el valor
const storedUser  = JSON.parse(sessionStorage.getItem("user"));
const nick = JSON.parse(sessionStorage.getItem("nick"));
const firstName = JSON.parse(sessionStorage.getItem("firstName"));
const lastName = JSON.parse(sessionStorage.getItem("lastName"));
const email = JSON.parse(sessionStorage.getItem("email"));
const phoneNumber = JSON.parse(sessionStorage.getItem("phoneNumber"));
const business = JSON.parse(sessionStorage.getItem("business"));
const enterprise = JSON.parse(sessionStorage.getItem("enterprise"));
const pwd = JSON.parse(sessionStorage.getItem("pwd"));

export const useStore = create((set, get) => ({
  //Datos de usuario
  users: [],
  nicks:[],
  firstNames:[],
  lastNames:[],
  profileImage:[],
  emails:[],
  phoneNumbers:[],
  businesss:[],
  enterprises:[],
  pwds: [],
  products: [],
  customers: [],
  orders: [],
  currentUser:  storedUser ,
  userType: storedUserType || null,
  //Datos de las ordenes
  businessType: 'Empresas',
  // Campos comunes
  solicitante: [],
  proveedor: [],
  numeroOrden: [],
  fechaEntrega: [],
  fechaCreacion: [],
  terminosPago: [],
  metodoEnvio: [],
  detallesProductos: [],
  infoEnvio: [],
  infoFacturacion: [],
  aprobaciones: [],
  observaciones: [],
  totalOrden: [],
  // Campos diferenciados
  centroCostos: [],
  autorizacionesMultiples: [],
  contratos: [],
  inventario: [],
  proveedoresLocales: [],
  rotacionProductos: [],
  merchandising: [],


  //Set de los datos de usuario
  setUsers: (users) => set({ users }),
  setNick: (nick) => set({ nick }),
  setFirstName: (firstNames) => set({ firstNames }),
  setLastName: (lastNames) => set({ lastNames }),
  setEmail: (emails) => set({ emails }),
  setPhoneNumber: (phoneNumbers) => set({ phoneNumbers }),
  setBusiness: (businesss) => set({ businesss }),
  setEnterprise: (enterprises) => set({ enterprises }),
  setPwds: (pwds) => set({ pwds }),
  setProducts: (products) => set({ products }),
  setCustomers: (customers) => set({ customers }),
  setOrders: (orders) => set({ orders }),
  setCurrentUser :  (currentUser ) => set({ currentUser  }),
  setUserType: (userType) => set({ userType }),

 // Set de los datos de las Ordenes
 setBusinessType: (businessType) => set({ businessType }),
 setSolicitante: (solicitante) => set({ solicitante }),
 setProveedor: (proveedor) => set({ proveedor }),
 setNumeroOrden: (numeroOrden) => set({ numeroOrden }),
 setFechaEntrega: (fechaEntrega) => set({ fechaEntrega }),
 setFechaCreacion: (fechaCreacion) => set({ fechaCreacion }),
 setTerminosPago: (terminosPago) => set({ terminosPago }),
 setMetodoEnvio: (metodoEnvio) => set({ metodoEnvio }),
 setDetallesProductos: (detallesProductos) => set({ detallesProductos }),
 setInfoEnvio: (infoEnvio) => set({ infoEnvio }),
 setInfoFacturacion: (infoFacturacion) => set({ infoFacturacion }),
 setAprobaciones: (aprobaciones) => set({ aprobaciones }),
 setObservaciones: (observaciones) => set({ observaciones }),
 setTotalOrden: (totalOrden) => set({ totalOrden }),
 setCentroCostos: (centroCostos) => set({ centroCostos }),
 setAutorizacionesMultiples: (autorizacionesMultiples) => set({ autorizacionesMultiples }),
 setContratos: (contratos) => set({ contratos }),
 setInventario: (inventario) => set({ inventario }),
 setProveedoresLocales: (proveedoresLocales) => set({ proveedoresLocales }),
 setRotacionProductos: (rotacionProductos) => set({ rotacionProductos }),
 setMerchandising: (merchandising) => set({ merchandising }),


  
  // Funciones para Clientes
  addCustomer: (customer) => set((state) => ({
    customers: [...state.customers, customer]
  })),
  removeCustomer: (id) => set((state) => ({
    customers: state.customers.filter(customer => customer.id !== id)
  })),
  updateCustomer: (updatedCustomer) => set((state) => ({
    customers: state.customers.map(customer =>
      customer.id === updatedCustomer.id ? updatedCustomer : customer
    )
  })),
  getCustomerById: (id) => {
    const customer = get().customers.find(customer => customer.id === id);
    return customer || null;
  },

  // Funciones para Órdenes
  addOrder: (order) => set((state) => ({
    orders: [...state.orders, order]
  })),
  removeOrder: (id) => set((state) => ({
    orders: state.orders.filter(order => order.id !== id)
  })),
  updateOrder: (updatedOrder) => set((state) => ({
    orders: state.orders.map(order =>
      order.id === updatedOrder.id ? updatedOrder : order
    )
  })),
  getOrderById: (id) => {
    const order = get().orders.find(order => order.id === id);
    return order || null;
  },

  // Función para resetear campos de orden
  resetOrder: () => set({
    solicitante: [],
    proveedor: [],
    numeroOrden: [],
    fechaEntrega: [],
    fechaCreacion: [],
    terminosPago: [],
    metodoEnvio: [],
    detallesProductos: [],
    infoEnvio: [],
    infoFacturacion: [],
    aprobaciones: [],
    observaciones: [],
    totalOrden: [],
    centroCostos: [],
    autorizacionesMultiples: [],
    contratos: [],
    inventario: [],
    proveedoresLocales: [],
    rotacionProductos: [],
    merchandising: []
  }),

  // Función para obtener órdenes por usuario
  getOrdersByUser: (userId) => {
    const orders = get().orders.filter(order => order.userId === userId);
    return orders || [];
  }
}));