import axios from '../components/axios/axios';

const BASE_URL = 'https://www.inventory.raicotech.cl:4500';

export const apiService = {
  // Auth
  login: (credentials) => {
    const formData = new URLSearchParams();
    const loginData = {
      user: credentials.username,
      pwd: credentials.password
    };
    formData.append(JSON.stringify(loginData), '');
    
    return axios.post('/access/login', formData);
  },
  register: (userData) => axios.post('/access/register', userData),
  
  // Users
  getUsers: () => axios.post('/users/getUsers'),
  getUser: (id) => axios.post(`${BASE_URL}/users/getUser`, { id }),
  updateUser: (userData) => axios.post(`${BASE_URL}/users/updateUser`, userData),
  deleteUser: (id) => axios.post(`${BASE_URL}/users/deleteUser`, { id }),
  
  // Orders
  getOrders: () => axios.post(`${BASE_URL}/orders/getOrders`),
  createOrder: (orderData) => axios.post(`${BASE_URL}/orders/createOrder`, orderData),
  updateOrder: (orderData) => axios.post(`${BASE_URL}/orders/updateOrder`, orderData),
  deleteOrder: (id) => axios.post(`${BASE_URL}/orders/deleteOrder`, { id }),
  
  // Products
  getProducts: () => axios.post(`${BASE_URL}/products/getProducts`),
  addProduct: (productData) => axios.post(`${BASE_URL}/products/addProduct`, productData),
  updateProduct: (productData) => axios.post(`${BASE_URL}/products/updateProduct`, productData),
  deleteProduct: (id) => axios.post(`${BASE_URL}/products/deleteProduct`, { id }),
  
  // Dashboard
  getDashboardStats: () => axios.post(`${BASE_URL}/dashboard/getDashboardStats`),
}; 