import React from 'react'
import './nav_Profile.css'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'

const NavProfile = () => {
  const username = localStorage.getItem('username')

  return (
    <Dropdown>
      <Dropdown.Toggle>
        <img
          src="/path/to/default/avatar.png"
          alt="Profile"
        />
        <span>{username}</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/Perfil">
          <i className="bi bi-person"></i>
          Perfil
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/Logout">
          <i className="bi bi-box-arrow-right"></i>
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default NavProfile