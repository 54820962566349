import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Función para limpiar la sesión y redirigir al login
    const handleLogout = () => {
      // Eliminar el token y los datos del usuario
      sessionStorage.removeItem('userToken');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('userType');
      sessionStorage.removeItem('nick');

      // Redirigir a la página de inicio de sesión con un parámetro único para evitar caché
      const uniqueUrl = `/login`;
      navigate(uniqueUrl, { replace: true });

      // Forzar la recarga de la página para asegurar que no haya caché
      window.location.reload();
    };

    // Deshabilitar la caché para la página de inicio de sesión
    const disableCache = () => {
      window.history.pushState(null, '', window.location.href);
      
      window.onpopstate = () => {
        navigate('/login', { replace: true });
      };
    };

    // Ejecutar las funciones de logout y deshabilitar caché
    handleLogout();
    disableCache();
  }, [navigate]);

  return (
    <div id='Logout'>
      <h2>Logging out...</h2>
    </div>
  );
};

export default Logout;