import React, { useState } from "react";
import "./navBar.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import NavProfile from "./nav_Profile/nav_Profile.jsx";

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const storedUserType = JSON.parse(sessionStorage.getItem("userType"));

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      {/* Botón de menú móvil */}
      <button 
        className="menu-toggle d-md-none" 
        onClick={toggleMobileMenu}
      >
        <i className="bi bi-list"></i>
      </button>

      {/* Overlay para móvil */}
      <div 
        className={`sidebar-overlay ${isMobileMenuOpen ? 'show' : ''}`} 
        onClick={toggleMobileMenu}
      ></div>

      {/* Sidebar */}
      <nav className={`sidebar ${isMobileMenuOpen ? 'show' : ''}`}>
        <div className="sidebar-header">
          <Link to="/Dashboard" className="navbar-brand">
            <i className="bi bi-receipt-cutoff"></i>
            <span>Inventory</span>
          </Link>
        </div>

        <ul className="nav-menu">
          <li className="nav-item">
            <Link to="/Dashboard" className="nav-link">
              <i className="bi bi-speedometer2"></i>
              <span>Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <Dropdown>
              <Dropdown.Toggle as="div" className="nav-link">
                <i className="bi bi-cart"></i>
                <span>Orders</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/Orders">
                  <i className="bi bi-box"></i>
                  <span>Product - Order</span>
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/StoreOrder">
                  <i className="bi bi-shop"></i>
                  <span>Store - Order</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>

          {storedUserType === "Admin" && (
            <>
              <li className="nav-item">
                <Dropdown>
                  <Dropdown.Toggle as="div" className="nav-link">
                    <i className="bi bi-file-text"></i>
                    <span>Reports</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/SalesReport">
                      <i className="bi bi-bar-chart"></i>
                      <span>Sales Report</span>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/InventoryReport">
                      <i className="bi bi-box-seam"></i>
                      <span>Inventory Report</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>

              <li className="nav-item">
                <Dropdown>
                  <Dropdown.Toggle as="div" className="nav-link">
                    <i className="bi bi-gear"></i>
                    <span>Settings</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/UserManagement">
                      <i className="bi bi-person"></i>
                      <span>User Management</span>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/Settings">
                      <i className="bi bi-sliders"></i>
                      <span>System Config</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </>
          )}

          <li className="nav-item">
            <Dropdown>
              <Dropdown.Toggle as="div" className="nav-link">
                <i className="bi bi-people"></i>
                <span>Management</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/UserManagement">
                  <i className="bi bi-person-badge"></i>
                  <span>Employees</span>
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/Management/Business">
                  <i className="bi bi-briefcase"></i>
                  <span>Business Management</span>
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/Management/Enterprise">
                  <i className="bi bi-diagram-3"></i>
                  <span>Enterprise Management</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>

        <div className="nav-profile">
          <NavProfile />
        </div>
      </nav>
    </>
  );
};

export default NavBar;
