import React from 'react'
import './notfound.css'



function NotFound() {
  return (
    <div className="container mt-5 " id='NotFound'>
      <div className="card bg-transparent border-light" id='NotFound'>
        <div className="card-body" id='NotFound'>
          <div className=' text-Dark' id='NotFound'>Error 404 - Not Found</div>
        </div>
      </div>
    </div>
  )
}

export default NotFound