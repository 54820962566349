import { Badge, Button, ButtonGroup, Card } from 'react-bootstrap';
import "./OrderStatusTracker.css";

const OrderStatusTracker = ({ currentStatus, onStatusChange, orderId }) => {
  // Bootstrap variant configuration for each status
  const statusVariants = {
    'Completed': 'success',
    'In Progress': 'primary',
    'Pending': 'warning',
    'Cancelled': 'danger',
  };

  // Status translation mapping
  const statusTranslation = {
    'Completado': 'Completed',
    'En Progreso': 'In Progress',
    'Pendiente': 'Pending',
    'Cancelado': 'Cancelled'
  };

  // Available status options in desired order
  const statusOptions = ['Completed', 'In Progress', 'Pending', 'Cancelled'];

  // Translate current status if needed
  const translatedStatus = statusTranslation[currentStatus] || currentStatus;

  return (
    <Card className="status-tracker-card">
      <Card.Body className="p-2">
        <div className="d-flex flex-column align-items-center">
          <small className="text-muted mb-1">Order #{orderId}</small>
          <Badge 
            bg={statusVariants[translatedStatus]} 
            className="mb-2 status-badge-sm"
          >
            {translatedStatus}
          </Badge>
          
          <ButtonGroup vertical size="sm" className="w-100">
            {statusOptions.map((option) => (
              <Button
                key={option}
                variant={statusVariants[option]}
                onClick={() => onStatusChange(orderId, option)}
                className="btn-sm text-uppercase status-button"
                disabled={translatedStatus === option}
                size="sm"
              >
                {option}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </Card.Body>
    </Card>
  );
};

export default OrderStatusTracker;