import React, { useState, useEffect } from 'react';
import './customers.css';
import NavBar from '../navBar/navBar.jsx';
import { useStore } from '../store/store.jsx';
import { Form, Input, Button, message, Table, Modal } from 'antd';
import { apiService } from '../../services/api';

const { TextArea } = Input;
const { Search } = Input;

function Customers() {
  const { customers, setCustomers } = useStore();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => new Date(date).toLocaleDateString(),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button danger onClick={() => handleDeleteCustomer(record.id)}>
          Delete
        </Button>
      ),
    },
  ];

  const fetchCustomers = async (searchTerm = '') => {
    setSearchLoading(true);
    try {
      const response = await apiService.getCustomers(searchTerm);
      setCustomers(response.data.customers);
    } catch (error) {
      console.error('Error:', error);
      message.error('Error connecting to server');
    } finally {
      setSearchLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const showModal = () => {
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const customerData = {
        nick: localStorage.getItem('username'),
        pwd: localStorage.getItem('password'),
        name: values.name,
        email: values.email,
        phone: values.phone,
        description: values.description
      };

      const response = await fetch('http://localhost:3500/createCustomer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          [JSON.stringify(customerData)]: ''
        })
      });

      const data = await response.json();

      if (response.ok) {
        message.success('Customer created successfully');
        setIsModalVisible(false);
        form.resetFields();
        // Actualizar la lista de customers
        fetchCustomers();
      } else {
        message.error(data.data || 'Error creating customer');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Error connecting to server');
    } finally {
      setLoading(false);
    }
  };

  // Maneja la eliminación de un cliente
  const handleDeleteCustomer = (id) => {
    const updatedCustomers = customers.filter(customer => customer.id !== id);
    setCustomers(updatedCustomers); // Actualiza la lista de clientes
  };

  return (
    <div className='ResponsiveShiet' id='customers'>
      <div className='Body_Content' id='customers'>
        <div className='Tittle' id='customers'>
          <h2 id='customers'>Customers</h2>
        </div>
        <div className='NavBar' id='customers'>
          <NavBar />
        </div>

        <div className="search-section">
          <div className="search-header">
            <Search
              placeholder="Search customers by name, email or phone"
              onSearch={fetchCustomers}
              loading={searchLoading}
              enterButton
            />
            <Button type="primary" onClick={showModal}>
              Add Customer
            </Button>
          </div>
        </div>

        <Modal
          title="Add New Customer"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              name="name"
              label="Customer Name"
              rules={[{ required: true, message: 'Please input customer name!' }]}
            >
              <Input placeholder="Enter customer name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Customer Email"
              rules={[
                { required: true, message: 'Please input customer email!' },
                { type: 'email', message: 'Please enter a valid email!' }
              ]}
            >
              <Input placeholder="Enter customer email" />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Customer Phone"
              rules={[{ required: true, message: 'Please input customer phone!' }]}
            >
              <Input placeholder="Enter customer phone number" />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description (max 100 words)"
              rules={[
                { required: true, message: 'Please input description!' },
                {
                  validator: (_, value) => {
                    const wordCount = value?.trim().split(/\s+/).length || 0;
                    if (wordCount > 100) {
                      return Promise.reject('Description cannot exceed 100 words!');
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <TextArea
                placeholder="Enter customer description"
                rows={4}
                onChange={(e) => {
                  const wordCount = e.target.value.trim().split(/\s+/).length;
                  e.target.setAttribute('data-word-count', `${wordCount} words / 100 max`);
                }}
              />
            </Form.Item>

            <Form.Item className="modal-footer">
              <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Add Customer
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <div className='CustomerList'>
          <Table
            columns={columns}
            dataSource={customers}
            rowKey="id"
            loading={loading}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              showQuickJumper: true,
            }}
            scroll={{ x: true }}
          />
        </div>
      </div>
    </div>
  );
}

export default Customers;