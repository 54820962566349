import React, { useState } from 'react';
import { Card, Container, Row, Col, Tabs, Tab, Form, Button, Alert } from 'react-bootstrap';
import Nav from '../navBar/navBar.jsx';
import ConfigUsr from '../configusr/config_usr.jsx';
import { useStore } from '../store/store.jsx';
import CommentSection from './CommentSection/CommentSection.jsx'; // Importa el nuevo componente
import './perfil.css';

function Perfil() {
  const { currentUser } = useStore();
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [message, setMessage] = useState({ text: '', type: '' });

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmitPasswordChange = async (e) => {
    e.preventDefault();
    setMessage({ text: '', type: '' });

    // Validaciones básicas
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setMessage({ text: 'Las contraseñas nuevas no coinciden', type: 'danger' });
      return;
    }

    if (passwordData.newPassword.length < 6) {
      setMessage({ text: 'La contraseña debe tener al menos 6 caracteres', type: 'danger' });
      return;
    }

    try {
      // Aquí irá la llamada a tu API para cambiar la contraseña
      // const response = await axios.post('/api/change-password', passwordData);
      
      setMessage({ text: 'Contraseña actualizada exitosamente', type: 'success' });
      setShowPasswordForm(false);
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    } catch (error) {
      setMessage({ 
        text: error.response?.data?.message || 'Error al cambiar la contraseña', 
        type: 'danger' 
      });
    }
  };

  if (!currentUser) {
    return <div>No se ha encontrado información del usuario. Por favor, inicie sesión.</div>;
  }

  return (
    <div className='Container-Body'>
      <Nav />
      <Container className='container-user'>
        <Row className="justify-content-md-center">
          <Col xs={16} md={12}>
            <Card>
              <Card.Header>
                <h2>Perfil de Usuario</h2>
              </Card.Header>
              <Card.Body>
                <Tabs defaultActiveKey="perfil" id="perfil-tabs" className="mb-3">
                  <Tab eventKey="perfil" title="Perfil">
                    <div className='profile-container'>
                      <img src={currentUser.profileImage} alt="Perfil" className="profile-avatar" />
                      <h2 className="profile-bio"><strong>Nick:</strong><br />{currentUser.nick}</h2>
                      <h2 className="profile-bio"><strong>Name:</strong><br />{currentUser.username} {currentUser.lastname}</h2>
                      <p className="profile-bio"><strong>Email:</strong><br />{currentUser.email}</p>
                      <p className="profile-bio"><strong>Phone Number:</strong><br />{currentUser.phonenumber}</p>
                      <p className="profile-bio"><strong>Tipo de Usuario:</strong><br />{currentUser.userType}</p>
                      <p className="profile-bio"><strong>Negocio:</strong><br />{currentUser.bussiness}</p>
                      <p className="profile-bio"><strong>Empresa:</strong><br />{currentUser.company}</p>

                      {/* Integración del componente de comentarios */}
                      <CommentSection />

                      {/* Sección de cambio de contraseña */}
                      <div className="password-section">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h4>Contraseña</h4>
                          <Button 
                            variant="outline-primary"
                            onClick={() => setShowPasswordForm(!showPasswordForm)}
                          >
                            {showPasswordForm ? 'Cancelar' : 'Cambiar Contraseña'}
                          </Button>
                        </div>

                        {message.text && (
                          <Alert variant={message.type} className="mt-3">
                            {message.text}
                          </Alert>
                        )}

                        {showPasswordForm && (
                          <Form onSubmit={handleSubmitPasswordChange}>
                            <Form.Group className="mb-3">
                              <Form.Label>Contraseña Actual</Form.Label>
                              <Form.Control
                                type="password"
                                name="currentPassword"
                                value={passwordData.currentPassword}
                                onChange={handlePasswordChange}
                                required
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>Nueva Contraseña</Form.Label>
                              <Form.Control
                                type="password"
                                name="newPassword"
                                value={passwordData.newPassword}
                                onChange={handlePasswordChange}
                                required
                              />
                            </Form.Group>

                            <Form.Group className="mb-3">
                              <Form.Label>Confirmar Nueva Contraseña</Form.Label>
                              <Form.Control
                                type="password"
                                name="confirmPassword"
                                value={passwordData.confirmPassword}
                                onChange={handlePasswordChange}
                                required
                              />
                            </Form.Group>

                            <div className="d-flex justify-content-end">
                              <Button variant="primary" type="submit">
                                Actualizar Contraseña
                              </Button>
                            </div>
                          </Form>
                        )}
                      </div>
                    </div>
                  </Tab>
                  
                  <Tab eventKey="configuracion" title="Configuración">
                    <ConfigUsr />
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Perfil;