import React, { useState, useEffect } from 'react';
import './userManagement.css';
import { Table, Button, Modal, Form, Input, message, Switch, Upload, Card, Select } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';
import { useStore } from '../store/store.jsx'; // Importa el store
import axios from 'axios'; // Importa axios para hacer la petición
import Navbar from '../navBar/navBar.jsx';
import { SiNetdata } from 'react-icons/si';
import UserSearch from '../user/UserSearch'; // Importamos el componente UserSearch
import InsideRegister from '../register/insideRegister/insideRegister';
import backgroundImage from '../../assets/imgs/background.jpg';

const { Option } = Select;

const UserManagement = () => {
  const { users, setUsers, auth } = useStore(); // Agregamos auth del store
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [modalMode, setModalMode] = useState('create'); // 'create' o 'edit'
  const [currentUserType, setCurrentUserType] = useState(null);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  //se le asigna el url al getUsersUrl
  const getUsersUrl = "https://inventory.raicotech.cl:4500/getUsers"; //url de pruebas

  // Actualizar config para usar credenciales de la sesión
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      'Accept': 'application/x-www-form-urlencoded'        
    },
    data: {},
    params: {
      nick: auth?.credentials?.nick,  
      pwd: auth?.credentials?.pwd  
    }
  };

  const columns = [
    {
      title: 'Nick',
      dataIndex: 'nick',
      key: 'nick',
      sorter: (a, b) => a.nick.localeCompare(b.nick),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Business',
      dataIndex: 'business',
      key: 'business',
      filters: [
        { text: 'Sales', value: 'Sales' },
        { text: 'Marketing', value: 'Marketing' },
        { text: 'IT', value: 'IT' },
      ],
      onFilter: (value, record) => record.business === value,
    },
    {
      title: 'Enterprise',
      dataIndex: 'enterprise',
      key: 'enterprise',
    },
    {
      title: 'User Type',
      dataIndex: 'userType',
      key: 'userType',
      filters: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Admin', value: 'Admin' },
      ],
      onFilter: (value, record) => record.userType === value,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className="action-buttons">
          <Button
            icon={<EditOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(record);
            }}
            type="primary"
            ghost
          >
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(record.nick);
            }}
            type="primary"
            danger
            ghost
          >
            Delete
          </Button>
        </div>
      ),
    }
  ];

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://inventory.raicotech.cl:4500/getUsers",
        JSON.stringify({ 
          nick: "jroco", 
          pwd: "Qwerty12345#" 
        }), 
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: false
        }
      );

      if (response.data.users && Array.isArray(response.data.users)) {
        const formattedUsers = response.data.users.map((user, index) => ({
          ...user,
          key: user.nick,
          id: user.id || index + 1,
        }));
        setUsers(formattedUsers);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("Error loading users");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    // Obtener el tipo de usuario del localStorage
    const userType = localStorage.getItem('userType');
    setCurrentUserType(userType);
  }, []);

  const handleEdit = (record) => {
    setModalMode('edit');
    setSelectedUser(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleDelete = (nick) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this user?',
      content: 'This action cannot be undone',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => deleteUser(nick)
    });
  };

  const createUser = async (values) => {
    try {
      const payload = {
        ...config.params,
        ...values
      };

      const response = await axios.post(
        "https://inventory.raicotech.cl:4500/register",
        JSON.stringify(payload),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: false
        }
      );

      if (response.status === 200) {
        message.success('User created successfully');
        fetchUsers();
        setIsModalVisible(false);
        form.resetFields();
      }
    } catch (error) {
      message.error('Error creating user');
    }
  };

  const updateUser = async (values) => {
    try {
      const payload = {
        ...config.params,
        ...values,
        originalNick: selectedUser.nick
      };

      const response = await axios.post(
        "https://inventory.raicotech.cl:4500/updateUser",
        JSON.stringify(payload),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: false
        }
      );

      if (response.status === 200) {
        message.success('User updated successfully');
        fetchUsers();
        setIsModalVisible(false);
        form.resetFields();
      }
    } catch (error) {
      message.error('Error updating user');
    }
  };

  const deleteUser = async (nick) => {
    try {
      const payload = {
        ...config.params,
        targetNick: nick
      };

      const response = await axios.post(
        "https://inventory.raicotech.cl:4500/deleteUser",
        JSON.stringify(payload),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: false
        }
      );

      if (response.status === 200) {
        message.success('User deleted successfully');
        fetchUsers();
      }
    } catch (error) {
      message.error('Error deleting user');
    }
  };

  const handleModalOk = () => {
    form.validateFields()
      .then(values => {
        if (modalMode === 'create') {
          createUser(values);
        } else {
          updateUser(values);
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCreate = () => {
    setModalMode('create');
    setSelectedUser(null);
    form.resetFields();
    setIsModalVisible(true);
  };

  return (
    <div 
      className='user-management-container'
      style={{ 
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh'
      }}
    ><Navbar />
      <div className='management-content-wrapper'>
        <div className='management-header'>
          <h2>User Management</h2>
          
        </div>

        <div className='management-content'>
          <div className="actions-container">
            <div className="search-wrapper">
              <div className="search-section">
                <UserSearch />
              </div>
              <div className="buttons-group">
                <Button 
                  type="primary"
                  onClick={() => setShowRegisterModal(true)}
                  className="action-button"
                  icon={<PlusOutlined />}
                >
                  New User
                </Button>
                <Button 
                  type="primary"
                  onClick={handleCreate}
                  className="action-button"
                  icon={<EditOutlined />}
                >
                  User Edit
                </Button>
                <Button 
                  type="primary"
                  danger
                  onClick={() => {
                    if (selectedUser) {
                      handleDelete(selectedUser.nick);
                    } else {
                      message.warning('Please select a user first');
                    }
                  }}
                  className="action-button"
                  icon={<DeleteOutlined />}
                >
                  Delete User
                </Button>
              </div>
            </div>
          </div>

          <div className="table-section">
            <Table 
              columns={columns.map(col => {
                if (col.key === 'actions') {
                  return {
                    ...col,
                    render: (_, record) => (
                      currentUserType === 'Admin' && (
                        <div className="action-buttons">
                          <Button
                            icon={<EditOutlined />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEdit(record);
                            }}
                            type="primary"
                            ghost
                          >
                            Edit
                          </Button>
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(record.nick);
                            }}
                            type="primary"
                            danger
                            ghost
                          >
                            Delete
                          </Button>
                        </div>
                      )
                    )
                  };
                }
                return col;
              })}
              dataSource={users}
              loading={loading}
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
              }}
              scroll={{ x: true }}
              size="middle"
              bordered
              rowClassName={(record) => record.userType === 'Admin' ? 'admin-row' : ''}
              onRow={(record) => ({
                onClick: () => setSelectedUser(record),
              })}
            />
          </div>

          {/* Modal para crear/editar usuario */}
          <Modal
            title={modalMode === 'create' ? 'Create User' : 'Edit User'}
            visible={isModalVisible}
            onOk={handleModalOk}
            onCancel={() => setIsModalVisible(false)}
            width={720}
          >
            <Form
              form={form}
              layout="vertical"
            >
              <Form.Item
                name="nick"
                label="Nick"
                rules={[{ required: true, message: 'Please input nick!' }]}
              >
                <Input disabled={modalMode === 'edit'} />
              </Form.Item>

              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: 'Please input first name!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: 'Please input last name!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: 'Please input email!' },
                  { type: 'email', message: 'Please input valid email!' }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[{ required: true, message: 'Please input phone number!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="business"
                label="Business"
                rules={[{ required: true, message: 'Please select business!' }]}
              >
                <Select>
                  <Option value="Sales">Sales</Option>
                  <Option value="Marketing">Marketing</Option>
                  <Option value="IT">IT</Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="enterprise"
                label="Enterprise"
                rules={[{ required: true, message: 'Please input enterprise!' }]}
              >
                <Input />
              </Form.Item>

              {modalMode === 'create' && (
                <Form.Item
                  name="pwd"
                  label="Password"
                  rules={[{ required: true, message: 'Please input password!' }]}
                >
                  <Input.Password />
                </Form.Item>
              )}

              <Form.Item
                name="userType"
                label="User Type"
                rules={[{ required: true, message: 'Please select user type!' }]}
              >
                <Select>
                  <Option value="Normal">Normal</Option>
                  <Option value="Admin">Admin</Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>

          {selectedUser && (
            <Card className="user-details-card">
              <h3 className="card-title">Selected User Details</h3>
              <div className="user-info-grid">
                <div className="info-item">
                  <label>Nick:</label>
                  <span>{selectedUser.nick}</span>
                </div>
                <div className="info-item">
                  <label>First Name:</label>
                  <span>{selectedUser.firstName}</span>
                </div>
                <div className="info-item">
                  <label>Last Name:</label>
                  <span>{selectedUser.lastName}</span>
                </div>
                <div className="info-item">
                  <label>Email:</label>
                  <span>{selectedUser.email}</span>
                </div>
                <div className="info-item">
                  <label>Phone:</label>
                  <span>{selectedUser.phoneNumber}</span>
                </div>
                <div className="info-item">
                  <label>Business:</label>
                  <span>{selectedUser.business}</span>
                </div>
                <div className="info-item">
                  <label>Enterprise:</label>
                  <span>{selectedUser.enterprise}</span>
                </div>
                <div className="info-item">
                  <label>User Type:</label>
                  <span>{selectedUser.userType}</span>
                </div>
              </div>
            </Card>
          )}

          <InsideRegister 
            show={showRegisterModal}
            handleClose={() => setShowRegisterModal(false)}
            adminCredentials={auth?.credentials || {
              nick: "jroco",
              pwd: "Qwerty12345#"
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserManagement;