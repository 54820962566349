import { useRef, useState } from "react";
import axios from '../../axios/axios.jsx';
import './insideRegister.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const REGISTER_URL = 'https://inventory.raicotech.cl:4500/register';

const InsideRegister = ({ show, handleClose, adminCredentials }) => {
  const userRef = useRef();
  const errRef = useRef();

  const [nick, setNick] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [business, setBusiness] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [pwd, setPwd] = useState('');
  const [matchPwd, setMatchPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const clearForm = () => {
    setNick('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setBusiness('');
    setEnterprise('');
    setPwd('');
    setMatchPwd('');
    setErrMsg('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación de campos vacíos
    if (!nick || !firstName || !lastName || !email || !phoneNumber || !business || 
        !enterprise || !pwd || !matchPwd) {
      setErrMsg("Por favor, complete todos los campos obligatorios");
      return;
    }

    // Validaciones de formato
    if (!USER_REGEX.test(nick)) {
      setErrMsg("Nombre de usuario inválido. Debe comenzar con una letra y tener entre 4-24 caracteres");
      return;
    }
    if (!PWD_REGEX.test(pwd)) {
      setErrMsg("Contraseña inválida. Debe contener mayúsculas, minúsculas, números y caracteres especiales");
      return;
    }
    if (!EMAIL_REGEX.test(email)) {
      setErrMsg("Formato de email inválido");
      return;
    }
    if (pwd !== matchPwd) {
      setErrMsg("Las contraseñas no coinciden");
      return;
    }

    try {
      const formData = {
        nick: nick,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        business: business,
        enterprise: enterprise,
        pwd: pwd,
        userType: "Normal"
      };

      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify(formData),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: false
        }
      );

      if (response.status === 200) {
        clearForm();
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No hay respuesta del servidor');
      } else {
        switch (err.response.status) {
          case 400:
            setErrMsg('Datos inválidos o faltantes');
            break;
          case 409:
            setErrMsg('El usuario ya existe');
            break;
          case 500:
            setErrMsg('Error en el servidor');
            break;
          default:
            setErrMsg('Error en el registro: ' + (err.response.data.data || 'Error desconocido'));
        }
      }
      errRef.current.focus();
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered className="register-modal">
      <Modal.Header closeButton>
        <Modal.Title>Crear Nuevo Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="register-form-container">
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="nick">Username:</label>
                <input
                  type="text"
                  id="nick"
                  ref={userRef}
                  className="form-control"
                  onChange={(e) => setNick(e.target.value)}
                  value={nick}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="firstName">Nombre:</label>
                <input
                  type="text"
                  id="firstName"
                  className="form-control"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="lastName">Apellido:</label>
                <input
                  type="text"
                  id="lastName"
                  className="form-control"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="phoneNumber">Teléfono:</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  className="form-control"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="business">Negocio:</label>
                <input
                  type="text"
                  id="business"
                  className="form-control"
                  onChange={(e) => setBusiness(e.target.value)}
                  value={business}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="enterprise">Empresa:</label>
                <input
                  type="text"
                  id="enterprise"
                  className="form-control"
                  onChange={(e) => setEnterprise(e.target.value)}
                  value={enterprise}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="pwd">Contraseña:</label>
                <input
                  type="password"
                  id="pwd"
                  className="form-control"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="matchPwd">Confirmar Contraseña:</label>
                <input
                  type="password"
                  id="matchPwd"
                  className="form-control"
                  onChange={(e) => setMatchPwd(e.target.value)}
                  value={matchPwd}
                  required
                />
              </div>
            </div>

            <div className="button-group">
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Crear Usuario
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default InsideRegister;
