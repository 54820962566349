
import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import './create_order.css';
import Nav from '../../navBar/navBar.jsx';


const Create_Order = () => {
  const [formData, setFormData] = useState({
    businessType: 'Enterprise',
    // Campos comunes
    solicitante: '',
    proveedor: '',
    numeroOrden: '',
    fechaEntrega: '',
    fechaCreacion: '',
    terminosPago: '',
    metodoEnvio: '',
    detallesProductos: '',
    infoEnvio: '',
    infoFacturacion: '',
    aprobaciones: '',
    observaciones: '',
    totalOrden: '',
    // Campos diferenciados
    centroCostos: '',
    autorizacionesMultiples: '',
    contratos: '',
    inventario: '',
    proveedoresLocales: '',
    rotacionProductos: '',
    merchandising: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Lógica para enviar los datos
    console.log('Datos de la orden:', formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const empresasFields = () => (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Centro de Costos o Proyecto</Form.Label>
        <Col sm={9}>
          <Form.Control 
            name="centroCostos"
            value={formData.centroCostos}
            onChange={handleChange}
            required
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Autorizaciones Múltiples</Form.Label>
        <Col sm={9}>
          <Form.Control 
            name="autorizacionesMultiples"
            value={formData.autorizacionesMultiples}
            onChange={handleChange}
            required
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Contratos o Acuerdos</Form.Label>
        <Col sm={9}>
          <Form.Control 
            name="contratos"
            value={formData.contratos}
            onChange={handleChange}
            required
          />
        </Col>
      </Form.Group>
    </>
  );

  const negociosFields = () => (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Inventario (Stock Mínimo)</Form.Label>
        <Col sm={9}>
          <Form.Control 
            name="inventario"
            value={formData.inventario}
            onChange={handleChange}
            required
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Proveedores Locales</Form.Label>
        <Col sm={9}>
          <Form.Control 
            name="proveedoresLocales"
            value={formData.proveedoresLocales}
            onChange={handleChange}
            required
          />
        </Col>
      </Form.Group>
    </>
  );

  const tiendasFields = () => (
    <>
      {negociosFields()}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Rotación de Productos</Form.Label>
        <Col sm={9}>
          <Form.Control 
            name="rotacionProductos"
            value={formData.rotacionProductos}
            onChange={handleChange}
            required
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>Presentación/Merchandising</Form.Label>
        <Col sm={9}>
          <Form.Control 
            name="merchandising"
            value={formData.merchandising}
            onChange={handleChange}
            required
          />
        </Col>
      </Form.Group>
    </>
  );

  return (
    <div className='Container-Body' >
        <div className='navBar'><Nav/></div>
    <div className='create_order justify-content-center'>    
    <Card className="m-3">
      <Card.Header as="h2">Crear Orden de Compra</Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          {/* Selección de tipo de negocio */}
          <Form.Group as={Row} className="mb-4">
            <Form.Label column sm={3}>Tipo de Negocio</Form.Label>
            <Col sm={9}>
              <Form.Select 
                name="businessType"
                value={formData.businessType}
                onChange={handleChange}
                required
              >
                <option value="Empresas">Empresa</option>
                <option value="Negocios">Negocio</option>
                <option value="Tiendas">Tienda</option>
              </Form.Select>
            </Col>
          </Form.Group>

          {/* Campos Comunes */}
          <h4 className="mb-4 border-bottom pb-2">Información General</h4>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Solicitante</Form.Label>
                <Form.Control 
                  name="solicitante"
                  value={formData.solicitante}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Número de Orden</Form.Label>
                <Form.Control 
                  name="numeroOrden"
                  value={formData.numeroOrden}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Fecha Entrega</Form.Label>
                <Form.Control 
                  type="date"
                  name="fechaEntrega"
                  value={formData.fechaEntrega}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Proveedor</Form.Label>
                <Form.Control 
                  name="proveedor"
                  value={formData.proveedor}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Fecha Creación</Form.Label>
                <Form.Control 
                  type="date"
                  name="fechaCreacion"
                  value={formData.fechaCreacion}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Términos de Pago</Form.Label>
                <Form.Control 
                  name="terminosPago"
                  value={formData.terminosPago}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Campos Diferenciados */}
          <h4 className="mt-5 mb-4 border-bottom pb-2">Información Específica</h4>
          {formData.businessType === 'Empresas' && empresasFields()}
          {formData.businessType === 'Negocios' && negociosFields()}
          {formData.businessType === 'Tiendas' && tiendasFields()}

          {/* Sección Final */}
          <div className="mt-5">
            <Button variant="primary" size="lg" type="submit">
              Crear Orden
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
    </div>
    </div>
  );
};

export default Create_Order;